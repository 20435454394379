import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

// @ts-expect-error false positive image import failure
import imgContentBig from "../../assets/image/radios/pnc550-hytalk.png";
// @ts-expect-error false positive image import failure
import head1 from "../../assets/image/talking/head5.png";
// @ts-expect-error false positive image import failure
import head2 from "../../assets/image/talking/head6.png";
// @ts-expect-error false positive image import failure
import head3 from "../../assets/image/talking/head2-flipped.png";

interface ThemeProps {
  theme: DefaultTheme;
}
const ImageRight = styled(Box)`
  img {
    width: 63%;
  }
`;

const ShapeCard = styled(Box)`
width: 305px;
box-shadow: 0 32px 34px rgba(28, 27, 32, 0.2);
position: absolute;
top: 5%;
left: 0%;
transform: scale(0.85);
@media ${device.sm} {
  left: -4;
  transform: scale(1);
}
@media ${device.md} {
  left: -4%;
}`

  const ShapeCard2 = styled(Box)`
  width: 305px;
  box-shadow: 0 32px 34px rgba(28, 27, 32, 0.2);
  position: absolute;
  bottom: 10%;
  right: 5%;
  transform: scale(0.85);
  @media ${device.sm} {
    right: 5%;
    transform: scale(1);
  }
  @media ${device.md} {
    right: 5%;
  }
`;


const ShapeCard3 = styled(Box)`
  width: 305px;
  box-shadow: 0 32px 34px rgba(28, 27, 32, 0.2);
  position: absolute;
  bottom: -6%;
  left: 0%;
  transform: scale(0.85);
  @media ${device.sm} {
    left: 0%;
    transform: scale(1);
  }
  @media ${device.md} {
    left: 0%;
  }
`;

const HeadImage = styled.img`
  width: 55px;
  height: 55px;
`
const Content2 = () => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section className="mt-10 mb-10">
      <Container>
        <Row className="align-items-center">
        <Col lg="5" md="10" className="order-lg-1 mb-4">
            <div className="position-relative text-lg-left">
              <ImageRight
                className=""
                data-aos="fade-down"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <img src={imgContentBig} alt="" className="img-fluid" />
              </ImageRight>

              <ShapeCard
                bg="warning"
                p="18px"
                borderRadius={8}
                className="d-flex align-items-center"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="300"
              >
                <Box
                  width="30px"
                  minWidth="30px"
                  height="30px"
                  minHeight="30px"
                  bg="secondary"
                  color="light"
                  borderRadius="50%"
                  className="d-flex align-items-center justify-content-center"
                  mr={3}
                  pl={3}
                >
                  <HeadImage margin-right={2} src={head1} alt="" />
                </Box>

                <Box ml={3}>
                  <Title textColor="light" fontSize={16} lineHeight="20px" variant="card" fontWeight={300} mb={0}>
                    "Group of drunken lads being aggressive asked to leave, they are now heading towards the high street."
                  </Title>
                </Box>
              </ShapeCard>

              <ShapeCard2
                bg="secondary"
                p="18px"
                borderRadius={8}
                className="d-flex align-items-center justify-content-end"
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="1000"
              >
                <Box pr="10px">
                  <Title textColor="light" textAlign="right" fontSize={16} lineHeight="20px" variant="card" fontWeight={300} mb={0}>
                    "Thanks for the warning, I have just stopped them entering the harvester."
                  </Title>
                </Box>
                <Box
                  width="30px"
                  minWidth="30px"
                  height="30px"
                  minHeight="30px"
                  bg="secondary"
                  color="light"
                  borderRadius="50%"
                  className="d-flex align-items-center justify-content-center"
                  mr={3}
                  ml={3}
                >
                  <HeadImage margin-left={2} src={head2} alt="" />
                </Box>

              </ShapeCard2>

              {/* <ShapeCard3
                bg="primary"
                p="18px"
                borderRadius={8}
                className="d-flex align-items-center justify-content-end"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="1500"
              >
                <Box
                  width="30px"
                  minWidth="30px"
                  height="30px"
                  minHeight="30px"
                  bg="secondary"
                  color="light"
                  borderRadius="50%"
                  className="d-flex align-items-center justify-content-center"
                  mr={3}
                  ml={3}
                >
                  <HeadImage margin-left={2} src={head3} alt="" />
                </Box>
                <Box pr="10px">
                  <Title textColor="light" textAlign="right" fontSize={16} lineHeight="20px" variant="card" fontWeight={300} mb={0}>
                    
                    "We've got them on camera, looks like they are heading towards the taxi rank."
                  </Title>
                </Box>
              </ShapeCard3> */}
            </div>
          </Col>
          
          <Col lg="7" className="order-lg-2 mt-5 mt-lg-0">
            <div
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <div>
                <Title>Be productive.</Title>
                <Text mb={4}>
                  The PNC550 boasts a fully operational
                  android smart phone and integrated smart radio.
                  Make or receive phone calls and messages or
                  even install your business apps to get more from
                  your device.<br /><br />
                  It’s best suited to businesses who want a single
                  unified device to access our radio system
                  and our alert! application
                </Text>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content2;
