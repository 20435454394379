import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text } from "../../components/Core";
import { device } from "../../utils";
// @ts-expect-error false positive image import failure
import imgContent from "../../assets/image/radios/dispatch-1.png";

const ImgContainer = styled.div`
  margin-top: 50px;
  @media ${device.lg} {
    margin-top: 0px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(55%, -50%);
  }
  @media ${device.xl} {
    transform: translate(40%, -50%);
  }
  @media (min-width: 1400px) {
    transform: translate(25%, -50%);
  }
`;

interface ThemeProps {
  theme: DefaultTheme;
}

const ULStyled = styled.ul<ThemeProps>`
  list-style: none;
  margin: 0;
  padding-top: 15px;
  padding-left: 0;

  @media ${device.sm} {
    padding-top: 35px;
    display: flex;
    flex-wrap: wrap;
  }
  @media ${device.lg} {
    justify-content: space-between;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    justify-content: start;
    margin-bottom: 5px;
    width: 15rem;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.primary};
      position: relative;
      top: 9px;
      margin-right: 0.5rem;
    }
  }
`;

const Content1 = () => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section
      className="position-relative"
      py={["50px", null, "130px", null, "230px"]}
    >
      <Container>
        <Row className="align-items-center">
          <Col xl="6" lg="5" className=" position-static order-lg-2">
            <ImgContainer className=" pl-lg-5">
              <img
                src={imgContent}
                alt=""
                className="img-fluid"
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-once="true"
                // @ts-expect-error img does not expect css attribute
                css={`
                  box-shadow: ${({ theme }: ThemeProps) =>
                    `0 12px 84px ${theme.colors.shadow}`};
                  border-radius: 10px;
                `}
              />
            </ImgContainer>
          </Col>
          <Col xl="6" lg="7" className="order-lg-1 mt-5 mt-lg-0">
            <div
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <div>
                <Title>Take Control.</Title>
                <Text mb={4}>
                  Enhance your system with our powerful dispatch software packed
                  full of advanced features to improve your operator
                  productivity.
                </Text>

                <ULStyled>
                  <li>Engage multiple groups.</li>
                  <li>Location tracking.</li>
                  <li>Private calling.</li>
                  <li>Full-duplex calling.</li>
                  <li>Video streaming.</li>
                  <li>Stun and Revive.</li>
                  <li>Full analytics.</li>
                  <li>Voice &amp; Video Playback.</li>
                </ULStyled>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content1;
