import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { saturate, lighten } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";
import pdfBrochure from "../../assets/downloads/ShopSafe-ShopWatch-and-TownLink-Brochure.pdf";

// @ts-expect-error false positive image import failure
import imgHeroTab from "../../assets/image/radios/smart-trio.png";
// @ts-expect-error false positive image import failure
import imgL2HeroBg from "../../assets/image/heros/world3.png";

const SectionStyled = styled(Section)`
  background: url(${imgL2HeroBg}) no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.3;
`;

const ImgRight = styled(Box)`
  position: relative;
  /* right: 10px; */
  /* left: -100px; */
  @media ${device.xl} {
    right: -100px;
    /* position: absolute;
    top: 0;
    max-width: 100%;
    min-width: 100%;
    left: auto;
    transform: translateX(25%); */
  }
  @media ${device.lg} {
    right: -30px;
    /* position: absolute;
    top: 0;
    max-width: 100%;
    min-width: 100%;
    right: -400px;
    left: auto;
    transform: translateX(25%); */
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled bg="#f7f7fb" className="position-relative">
        <Overlay />
        <div className="pt-5"></div>
        <Container>
          <Row className="justify-content-center align-items-center position-relative">
            <Col md="6" sm="10" className="order-md-2 position-static">
              <ImgRight>
                <img
                  src={imgHeroTab}
                  alt=""
                  style={{ width: 430 }}
                  className="img-fluid"
                  data-aos="fade-left"
                  data-aos-duration="750"
                  data-aos-delay="1000"
                  data-aos-once="true"
                />
              </ImgRight>
            </Col>
            <Col md="6" className="order-md-1 ">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
                  <Title variant="hero" textColor="light">
                    Smart Radio &amp; Cameras
                  </Title>
                  {/* <Text mb={4} color="light" opacity={0.7}>
                    Create custom landing pages with Omega that convert more
                    visitors than any website. With lots of unique blocks, you
                    can easily build a page without coding.
                  </Text> */}
                  <div className="d-flex flex-column align-items-start pt-3">
                    <a href={pdfBrochure} download>
                      <Button mb={2}>Download our brochure</Button>
                    </a>
                  </div>
                </Box>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
